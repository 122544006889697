:root {
    --color-primary:53, 190, 177;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: "Montserrat", sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('img/bg-tile.jpg');
  background-repeat: repeat;
  
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.section {
    height: 100vh;  
    width: 90vw;
    /* display: grid; */
    
    /* justify-content: center; */
    align-items: center;
    
}

.section.main {
    display: grid;
    grid-template-columns: 1.7fr 1fr;
    grid-gap: 10px;
    max-width: 800px;
    width: 95vw;
    margin-top: -20px;
}

.title {
    text-align: center;
    padding-top: 50px;
}

.title.main {
    padding-top: 0;
}

.main-image {
    width: 80%;
    max-width: 500px;
}

.skill-icon {
    height: 40px;
    margin-right: 20px;
}

.project-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-details {
    margin-top: auto;
}

.project-card, .skill { 
    background-color: rgba(var(--color-primary), 0.3);
    width: 30%;
    min-width: 300px;
    border: solid 1px white;
    border-radius: 40px;
    margin: 20px;
    padding: 30px;
}

.project-card p {
    font-size: large;
    opacity: 0.6;
}

.project-card img {
    height: 100px;
    margin-bottom: 20px;
    border-radius: 50px;
}

.projects, .skills {
    display: flex;
    overflow: scroll;
    margin-top: 100px;
}

.skill li {
    text-align: left;
    margin-bottom: 5px;
    font-size: large;
}

.skill {
    padding-left: 40px;
    padding-right: 40px;
}

ul {
    list-style-type: none; /* Remove bullets */
    padding: 0!important; /* Remove padding */
}

.section input{
    width: 100%;
}

.section form {
    min-width: 300px;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}


.button-primary {
    border-radius: 40px!important;
    padding: 10px 25px 10px 25px!important;
    font-size: large!important;
    background-color: rgb(var(--color-primary))!important;
    border-color: white!important;
    border-width: 0px!important;
    max-width: 200px;
  }

  .button-social {
    width: 30px;
    margin: -10px 5px 10px 5px;
    cursor: pointer;
  }

  .form-control.input-primary {
    border: solid 1px rgba(255, 255, 255, 0.3);
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
  }

  .contact-form {
    width: 100%;
    max-width: 500px;
  }

  #contact img {
    width: 60%;
    max-width: 300px;
  }
  .input-primary::placeholder {
    color: rgba(255, 255, 255, 0.8)!important;
  }

  .img-thumbnail.main-image {
    border-radius: 50%!important;
    padding: 0.25rem;
    border: none!important;
    max-width: 300px;
    width: 100%;
  }

  .project {
    padding: 5% 10% 10% 10%;
    width: 100vw;
    max-width: 1200px;
  }

  .project button {
    float: inline-end;
  }

  .project p {
    font-size: medium;
  }

  .project p, h2 {
    text-align: left;
  }

  .slideshow {
    margin: 0 auto;
    overflow: hidden;
    position: relative;
  }

  .slideshow img {
    height: 100%;
    width: auto;
  }
  
  .slideshowSlider {
    white-space: nowrap;
    background-color: black;
  }
  
  .slide {
    display: inline-block;
    background-size: cover;
    background-position: center;
    height: 50vh;
    width: 100%;
    border-radius: 40px;
    opacity: 0;
    transition: opacity 0.6s ease;
    -webkit-transition: opacity 0.6s ease;
    -moz-transition: opacity 0.6s ease;
    -o-transition: opacity 0.6s ease;
  }
  
  /* Buttons */
  .slideshowDots {
    text-align: center;
  }
  .slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 0px;
    background-color: #c4c4c4;
  }

  .slideshowDot.active {
    background-color: #6a0dad;
  }

  /* .slideshowButton {
    position: relative;
    width: 50px;
  } */

  /* .lp-slideshow,
.lp-slideshow * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
} */

/* .lp-slideshow {
  width: 100%;
}

.lp-slideshow > .container {
  position: relative;
  margin: auto;
  width: 100%;
} */

.lp-slideshow > .container > .slide {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  opacity: 0;
  transition: opacity 0.6s ease;
  -webkit-transition: opacity 0.6s ease;
  -moz-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
}

.slide.active {
  opacity: 1;
}

/* .lp-slideshow > .container > .slide > .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

/* .lp-slideshow > .container > .slide > .number-text {
  color: #fff;
  font-size: 1rem;
  padding: 8px 12px;
  position: absolute;
  top: 0;
} */

/* .lp-slideshow > .container > .slide > .caption-text {
  color: #fff;
  font-size: 1.3rem;
  padding: 8px 12px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
} */

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  font-weight: bold;
  transition: background-color 0.6s ease;
  -webkit-transition: background-color 0.6s ease;
  -moz-transition: background-color 0.6s ease;
  -o-transition: background-color 0.6s ease;
}
 .prev,
 .next {
  background-color: rgba(0, 0, 0, 0.2);
}

 .prev:hover,
 .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

 .prev {
  left: 0;
  border-radius: 0 3px 3px 0;
}

.media {
    width: 100%;
    height: 30rem;
    object-fit: contain;
  }
  
  .carousel {
    position: relative;
    margin-top: 40px;
  }
  
  .item {
    background-color: black;
  }


.btn-prev,
.btn-next {
  position: absolute;
  font-size: 50px;
  color: lightgray;
  cursor: pointer;
  top: 13rem;
}
.btn-prev {
  left: 1rem;
}
.btn-next {
  right: 1rem;
}

.line-break {
    white-space: pre-line;
  }

